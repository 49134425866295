import { Parser } from 'expr-eval'
import { resolveRealPath, useManagerFormValue, type INodeField } from '@manager'
import { extractVariables, resolveCalculatedVariable } from './utils'

export const useFormula = (
  node: INodeField,
  parentPath: string | undefined,
) => {
  const { formValue } = useManagerFormValue()
  const parser = new Parser()
  let formula = node.formula
  const evaluated = computed(() => {
    try {
      // @ts-expect-error
      return parser.parse(formula).evaluate(formValue?.value)
    } catch (e) {
      return undefined
    }
  })

  if (formula) {
    const variables = extractVariables(formula)

    for (const variable of variables) {
      let path = variable

      if (parentPath) {
        try {
          path = resolveRealPath(variable, parentPath)
        } catch (error) {
          console.error(error)
        }
      }
      formula = formula.replace(variable, resolveCalculatedVariable(path))
    }
  }

  return { formula, evaluated }
}
