export function extractVariables(formula: string) {
  const regex =
    /[\w.]+\[]\.\w+|[\w.]+\[\d+]\.[\w.]+|[\w.]+\[\d+]|[\w.]+\.\w+|[\w.]+\.\w+\.\w+|\b[a-zA-Z_]\w*\b/g
  const matches = formula.match(regex)
  return Array.from(new Set(matches || []))
}

export function resolveCalculatedVariable(variable: string) {
  return variable.replace(/(\w+)$/, 'calculated_$1')
}
